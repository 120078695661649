<template>
  <div>

    <OAlert v-if="code.application === 'atext'" variant="info">
      <strong>
        This code belongs to our mobile app "Second Phone Number"
      </strong>
    </OAlert>

    <OAlert v-if="code.application === 'prank'" variant="info">
      <strong>
        This code belongs to our mobile app "Prank Call"
      </strong>
    </OAlert>

    <OAlert v-if="isSuccess && code.application === 'spoof'" variant="info">
      <strong>
        {{ successMessage }}
      </strong>
    </OAlert>

    <OAlert v-if="!isSuccess && errorMessage && code.application === 'spoof'" variant="error">
      <strong>
        {{ errorMessage }}
      </strong>
    </OAlert>

    <OAlert variant="success">
      Discount codes work only with the correct account. If you have more than one account, be sure to use the code with
      the right one.
      <div v-if="code.username" style="text-align: center; margin-top: 10px;">
        <div style="text-align: center"><strong class="rainbow-text">{{ code.username }}</strong></div>
      </div>
    </OAlert>

    <div style="display: flex; justify-content: center;">
      <img v-if="isSuccess" width="75" src="../assets/order/ok.svg" alt="ok">
      <img v-else width="75" src="../assets/order/pending.svg" alt="pending">
    </div>

    <div style="display: flex; justify-content: center; margin-top: 5px;">
      <div class="code-status">{{ statusMessage }}</div>
    </div>

    <div style="display: flex; justify-content: space-between; margin-top: 20px;">
      <div>Code:</div>
      <div><strong>{{ code.code }}</strong></div>
    </div>

    <div style="display: flex; justify-content: space-between; margin-top: 20px;">
      <div>Expires:</div>
      <div><strong>{{ validUntil }}</strong></div>
    </div>

    <div v-if="code.application === 'spoof'">
      <div v-if="isSuccess" style="margin-top: 20px;">
        <OButton
          :to="{name: 'credits'}"
          theme="primary"
          type="button"
        >
          GO TO CREDITS
        </OButton>
      </div>

      <div v-if="!isSuccess && !code.expired" style="margin-top: 20px;">
        <OButton
          @click="applyCode"
          theme="primary"
          type="link"
          style="width: 100%"
        >
          APPLY
        </OButton>
      </div>
    </div>

    <div v-if="code.application === 'atext'">
      <div style="margin-top: 20px;">
        <OButton
          @click="gotoAtext"
          theme="primary"
          type="button"
          style="width: 100%"
        >
          OPEN APP
        </OButton>
      </div>
    </div>

    <div v-if="code.application === 'prank'">
      <div style="margin-top: 20px;">
        <OButton
          @click="gotoPrank"
          theme="primary"
          type="link"
          style="width: 100%"
        >
          OPEN APP
        </OButton>
      </div>
    </div>

  </div>
</template>

<script>

import OButton from '@/objects/OButton.vue'
import OAlert from '@/objects/OAlert.vue'

export default {
  name: 'CodeStatus',
  components: { OAlert, OButton },
  props: {
    code: {
      type: Object, required: true
    }
  },
  data: () => (
    {
      showCopyTxId: false,
      showCopyOrderId: false
    }
  ),
  computed: {
    isSuccess () {
      return !!this.code.status
    },
    errorMessage () {
      if (this.code.expired) {
        return 'Sorry, this code is no longer valid as it has expired.'
      }
      return ''
    },
    successMessage () {
      if (this.code.code_type === 'bonus_credit_percentage') {
        return 'Successfully applied ' + this.code.credits + '% more credits.'
      }
      if (this.code.code_type === 'free_credit') {
        return 'Successfully added ' + this.code.credits + ' credits!'
      }
      return ''
    },
    statusMessage () {
      if (this.code.code_type === 'bonus_credit_percentage') {
        return this.code.credits + '% bonus code'
      }
      if (this.code.code_type === 'free_credit') {
        return this.code.credits + ' credits'
      }
      return ''
    },
    validUntil () {
      // Create a new Date object from the string (parse with timezone)
      const date = new Date(this.code.valid_until * 1000)

      // Use Intl.DateTimeFormat to format the date in a shorter way
      const options = {
        year: 'numeric', // "2024"
        month: 'short', // "Oct"
        day: 'numeric', // "9"
        hour: 'numeric', // "11"
        minute: 'numeric', // "00"
        hour12: true // Use 12-hour format (AM/PM)
      }

      // Format the date
      return new Intl.DateTimeFormat('en-US', options).format(date)
    }
  },
  methods: {
    gotoAtext () {
      window.location.href = 'https://play.google.com/store/apps/details?id=dotsoa.anonymous.texting'
    },
    gotoPrank () {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.tiggzi.project54500'
    },
    applyCode () {
      window.location.href = '/script25/code.php?code=' + this.code.code
    }
  }
}
</script>

<style lang="scss">

.copy2clipboard {
  cursor: pointer;
}

.code-status {
  font-weight: bold;
  font-size: 1.5em;
}

.p-toast {
  position: absolute;
  margin-top: -30px;
  margin-left: -30px;
  border-radius: 4px;
  background-color: #333;
  padding: 4px;
  color: #ffffff;
  font-size: 0.8em;
}

/* Alternative rainbow effect */
@keyframes rainbowFade {
  0% {
    color: #ff0000;
  }
  20% {
    color: #ff8000;
  }
  40% {
    color: #000000;
  }
  60% {
    color: #0000ff;
  }
  80% {
    color: #cf26ff;
  }
  100% {
    color: #ff0000;
  }
}

.rainbow-text {
  animation: rainbowFade 5s infinite;
}

</style>
