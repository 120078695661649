exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OAlert_alert_1cSCV{position:relative;padding:1em 1em;margin-bottom:1em;border:1px solid transparent;border-radius:10px}.OAlert_alert_1cSCV,.OAlert_alert_1cSCV a{text-transform:none;letter-spacing:normal}.OAlert_alert_1cSCV a{color:inherit;font-weight:700;text-decoration:underline}.OAlert_alert-info_2QWmx{background-color:#ffe0da;border-color:#ffd0c8;color:#993b2b}.OAlert_alert-success_zP7jQ{background-color:#d0f1e2;border-color:#b9e9d3;color:#0d6e41}.OAlert_alert-warning_2mjGW{background-color:#ffe9d3;border-color:#ffdebd;color:#995714}.OAlert_alert-error_2s_qF{background-color:red;border-color:red;color:#fff}", ""]);

// exports
exports.locals = {
	"alert": "OAlert_alert_1cSCV",
	"alert-info": "OAlert_alert-info_2QWmx OAlert_alert_1cSCV",
	"alert-success": "OAlert_alert-success_zP7jQ OAlert_alert_1cSCV",
	"alert-warning": "OAlert_alert-warning_2mjGW OAlert_alert_1cSCV",
	"alert-error": "OAlert_alert-error_2s_qF OAlert_alert_1cSCV"
};