<template>
  <l-default>

    <OAlert v-if="error" variant="error">
      <strong>
        {{ error }}
      </strong>
    </OAlert>

    <CCodeStatus v-if="code" :code="code"/>

  </l-default>
</template>

<script>
import LDefault from '@/layouts/LDefault'
import { mapGetters } from 'vuex'
import CCodeStatus from '@/components/CCodeStatus.vue'
import OAlert from '@/objects/OAlert.vue'

const getApi = () => import('@/services/api')

export default {
  name: 'PageCodeStatus',
  components: {
    OAlert,
    CCodeStatus,
    LDefault
  },
  data: function () {
    return {
      error: '',
      code: null,
      interval: null
    }
  },
  computed: {
    ...mapGetters({
      credits: 'getCredits',
      isActive: 'isActive',
      email: 'getEmail'
    })
  },
  methods: {
    async load () {
      if (!this.$recaptcha) {
        let self = this
        setTimeout(function () { self.load() }, 1000)
        return
      }
      const api = await getApi()
      const token = await this.$recaptcha('code')
      const result = await api.getCodeStatus({
        code: this.$route.query.code,
        token
      })
      this.hideLoader()
      if (result.error) {
        this.error = result.message
        return false
      }
      this.code = result.data
    },
    showLoader () {
      this.loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        color: '#f00',
        width: 125,
        height: 125
      })
    },
    hideLoader () {
      this.loader.hide()
    }
  },
  mounted () {
    this.showLoader()
    this.load()
  }
}
</script>

<style lang="scss">
h2 {
  color: $primary;
}

.c-grid {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 0 1rem;
}

.c-grid__title {
  margin: 0;
  padding: 0.25em 0;
  text-transform: uppercase;
}

.c-grid__item {
  margin: 0 0 0.5rem;
}

.u-ml-auto {
  margin-left: auto;
}

.u-mb-3 {
  margin-bottom: 1.5rem;
}
</style>
